import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/for-business.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <div
          className="padding sasaboxbg text-center zb-lazy  b-loaded"
          style={{
            backgroundImage: `url(${withPrefix(
              "images/for-business/hero.png"
            )})`,
          }}
        >
          <div className="row collapse">
            <div className="large-12 columns text-center">
              <h1 style={{ color: "#ffffff" }}>Dla biznesu</h1>
            </div>
          </div>
        </div>
        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-8 large-centered medium-12 small-12 columns">
              <p className="p1">
                Jesteśmy grupą zmotywowanych, młodych ludzi, którzy chcą
                wykorzystać swoją wiedzę i doświadczenie pracując na rzecz
                pacjentów na całym świecie. Realizacja naszych akcji jest jednak
                niemożliwa bez wsparcia darczyńców, instytucji i biznesu.
              </p>
              <p className="p1">
                Jeżeli Twoja firma jest obecna w jednym z miejsc gdzie
                prowadzimy nasze działania – jesteśmy idealnym partnerem, aby
                zadbać o zdrowie tamtejszej społeczności, do której mogą należeć
                Twoi pracownicy lub klienci. Nasza wiedza i zdobyte
                doświadczenia są towarem, który chętnie zamienimy na możliwość
                realizowania kolejnych akcji i pomoc kolejnym chorym.
              </p>
              <p className="p1">
                Prośba o wsparcie nie dotyczy tylko środków finansowych. Jeżeli
                Twoja firma może dla nas coś przewieźć, coś zbudować, czegoś nas
                nauczyć… w stopce na dole strony znajdziesz nasze dane
                kontaktowe. Czekamy na kontakt od Ciebie!
              </p>
              <p className="p1">
                Współpraca może dotyczyć realizowanych przez nas projektów, a
                także nowych przedsięwzięć realizujących cele Fundacji SASA i
                podmiotu wspierającego.
              </p>
              <p>Kontakt dla partnerów biznesowych:</p>
              <p>
                <strong>Marcin Krajmas</strong>
                <br />
                marcin.krajmas@sasafoundation.com
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
